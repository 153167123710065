var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SettingsTopBar',{attrs:{"pathBefore":"Settings /","pathCurrent":_vm.$t('Product system wizard'),"showBackButton":true,"showSubmitButton":false}}),_c('div',{staticClass:"c-checklist-field-index"},[_c('ASelect',{staticClass:"c-checklist-field-index__dropdown",attrs:{"value":_vm.wizardData.productSystem ? _vm.wizardData.productSystem.id || _vm.wizardData.productSystem : null,"filterOption":"","optionFilterProp":"children","show-search":""},on:{"change":(id) => _vm.onSelectUpdate(id)}},_vm._l((_vm.productSystems),function(dropdownOption){return _c('ASelectOption',{key:dropdownOption.id || dropdownOption,staticClass:"c-language-picker__option u-b1",attrs:{"data-test":`dropdown-${dropdownOption.label}`,"value":dropdownOption.id || dropdownOption}},[(dropdownOption.label)?[_vm._v(" "+_vm._s(_vm.$t(dropdownOption.label)))]:[_vm._v(" "+_vm._s(dropdownOption.name || dropdownOption)+" ")]],2)}),1),_vm._l((_vm.locales),function(locale,index){return _c('div',{key:locale.code,staticClass:"c-checklist-field-index__segment"},[_c('h4',{staticClass:"u-a2 c-checklist-field-index__name"},[_vm._v(" "+_vm._s(_vm.$t(locale.name))+" ")]),_c('div',{staticClass:"c-checklist-field-index__field"},[(_vm.isNew || _vm.listOfFiles[index].length === 0)?_c('AUploadDragger',{attrs:{"customRequest":(data) => {
                            _vm.customRequest(
                                data,
                                {
                                    editablePropertyName: 'image',
                                    fileType: 'image',
                                    imageType: 'wizardImage',
                                },
                                locale.code
                            );
                        },"remove":(file) => {
                            _vm.onRemove(file, 'image');
                        },"listType":"picture","name":"file"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('Kliknite ovdje ili dovucite sliku koju želite postaviti'))+" ")])]):_c('AUploadDragger',{attrs:{"customRequest":(data) => {
                            _vm.customRequest(
                                data,
                                {
                                    editablePropertyName: 'image',
                                    fileType: 'image',
                                    imageType: 'wizardImage',
                                },
                                locale.code
                            );
                        },"remove":(file) => {
                            _vm.onRemove(file, 'image');
                        },"fileList":_vm.listOfFiles[index],"listType":"picture","name":"file"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('Kliknite ovdje ili dovucite sliku koju želite postaviti'))+" ")])])],1),_c('div',{staticClass:"c-checklist-field-index__field"},[_c('ATextarea',{attrs:{"value":_vm.getDescription(locale.code),"data-name":'description'},on:{"change":(element) => _vm.descriptionChanged(element.target.value, locale.code)}})],1)])})],2),_c('AButton',{directives:[{name:"debounce",rawName:"v-debounce:800ms",value:(_vm.onSaveOrUpdate),expression:"onSaveOrUpdate",arg:"800ms"}],staticClass:"generic-button u-a2--bold c-cms-index-save-button",attrs:{"data-test":"createOrUpdateEntity"}},[_vm._v(" "+_vm._s(_vm.$t('Spremi'))+" ")]),(!_vm.isNew)?_c('AButton',{directives:[{name:"debounce",rawName:"v-debounce:800ms",value:(_vm.onHandleDelete),expression:"onHandleDelete",arg:"800ms"}],staticClass:"danger-button u-b0 u-a2--bold c-cms-index-delete-button"},[_vm._v(" "+_vm._s(_vm.$t('Obriši'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue } from 'vue-property-decorator';
import { CustomFileRequest } from '@/interfaces/general/CustomFileRequest';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { UploadFile } from 'ant-design-vue/types/upload';
import { convertFileToAntdFile, uploadFile } from '@/helpers/CmsIndexHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProductSystemWizard from '@/models/ProductSystemWizard';
import Locale from '@/models/Locale';
import LocaleRepository from '@/repositories/LocaleRepository';
import ProductSystem from '@/models/ProductSystem';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import ProductSystemWizardRepository from '@/repositories/ProductSystemWizardRepository';
import ProductSystemWizardTranslation from '@/models/ProductSystemWizardTranslation';
import Image from '@/models/Image';

@Component({
    name: 'NewProductSystemWizard',
    components: { SettingsTopBar },
})
export default class NewProductSystemWizard extends Vue {
    private wizardData: ProductSystemWizard = new ProductSystemWizard();
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedLocale = null;
    private isNew = true;
    private listOfFiles: any[][] = [];

    private get locales() {
        return LocaleRepository.getAll();
    }
    private get productSystems() {
        return ProductSystemRepository.getAll();
    }

    private async customRequest(
        { file, onSuccess, onError }: any,
        { fileType, imageType }: CustomFileRequest,
        locale: string
    ) {
        this.loadingOverlay.start();
        let uploadedFile;

        const formData = new FormData();

        try {
            uploadedFile = await uploadFile(file, fileType, imageType, undefined, this.selectedLocale);
        } catch (e) {
            onError();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.loadingOverlay.stop();

        const image = convertFileToAntdFile(uploadedFile.data);
        if (this.wizardData && this.wizardData.translations.length) {
            const index = this.wizardData.translations.findIndex((t) => t.locale === locale);
            if (index >= 0) {
                this.wizardData.translations[index].image = {
                    image: image.url,
                    uuid: image.uid,
                    title: image.title,
                    fileSize: String(image.size),
                    type: 'image',
                } as Image;
            }
        }

        this.emitDataChanges(true);
        onSuccess(formData);
    }

    private onRemove(file: UploadFile) {
        const removedFileIndex = this.wizardData.translations.findIndex((t: ProductSystemWizardTranslation) => {
            return t.image?.id === file.uid;
        });
        if (removedFileIndex === -1) {
            return;
        }
        this.wizardData.translations[removedFileIndex].image = null;
        this.emitDataChanges(true);
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }

    private onSelectUpdate(id: string) {
        if (!this.wizardData) {
            return;
        }
        const selectedOption = this.productSystems.find((option: any) => {
            return option.id === id;
        });
        if (selectedOption === undefined) {
            this.wizardData.productSystem = null;
            return;
        }
        this.wizardData.productSystem = selectedOption;
    }
    private async onSaveOrUpdate() {
        this.loadingOverlay.start();
        if (this.isNew) {
            try {
                await ProductSystemWizard.createNew(this.wizardData);
                this.$router.back();
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: (e as Error).message,
                });
            }
        } else {
            try {
                await ProductSystemWizard.updateExisting(this.wizardData.id, this.wizardData);
                this.$router.back();
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: (e as Error).message,
                });
            }
        }
        this.loadingOverlay.stop();
    }

    private async onHandleDelete() {
        try {
            this.loadingOverlay.start();
            await ProductSystemWizard.deleteExisting(this.wizardData.id);
            this.loadingOverlay.stop();
            this.$router.back();
        } catch (e) {
            this.loadingOverlay.stop();
        }
    }
    private getImage(localeCode: string) {
        if (!this.wizardData || !this.wizardData.translations || this.isNew) {
            return [];
        }
        const image = this.wizardData.translations.find((t) => t.locale === localeCode)?.image ?? undefined;
        if (image) {
            const fileList = [convertFileToAntdFile(image)];
            return fileList.map((f) => {
                return {
                    ...f,
                    thumbUrl: f.url,
                };
            });
        }
        return [];
    }
    private getDescription(localeCode: string) {
        if (!this.wizardData || !this.wizardData.translations || this.isNew) {
            return undefined;
        }
        return this.wizardData.translations.find((t) => t.locale === localeCode)?.description;
    }

    private descriptionChanged(value: string, localeCode: string) {
        if (this.wizardData && this.wizardData.translations.length) {
            const index = this.wizardData.translations.findIndex((t) => t.locale === localeCode);
            if (index >= 0) {
                this.wizardData.translations[index].description = value;
            }
        }
    }

    private async created() {
        this.loadingOverlay.start();
        try {
            await Locale.getAll();
            await ProductSystem.getAll();
        } catch (e) {
            this.loadingOverlay.stop();
            return;
        }
        if (this.$route.params.entityId !== 'new') {
            try {
                await ProductSystemWizard.getById(this.$route.params.entityId);
            } catch (e) {
                this.loadingOverlay.stop();
                return;
            }
            const data = ProductSystemWizardRepository.getById(this.$route.params.entityId);
            if (data) {
                this.wizardData = {
                    ...data,
                    translations: this.locales.map((l) => {
                        const translation = data.translations.find((t) => t.locale === l.code);
                        if (translation) {
                            return translation as ProductSystemWizardTranslation;
                        }
                        return {
                            image: null,
                            locale: l.code,
                            description: '',
                        } as ProductSystemWizardTranslation;
                    }),
                } as ProductSystemWizard;
                this.isNew = false;
                this.locales.forEach((l) => {
                    this.listOfFiles.push(this.getImage(l.code) ?? []);
                });
            }
        } else {
            this.wizardData = {
                productSystem: null,
                translations: this.locales.map((l) => {
                    return {
                        image: null,
                        locale: l.code,
                        description: '',
                    } as ProductSystemWizardTranslation;
                }),
            } as ProductSystemWizard;
            this.isNew = true;
        }
        this.loadingOverlay.stop();
    }
}
